import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import ContentProdukteLeistungen from '../components/ContentProdukteLeistungen'
import SEO from '../components/SEO'
import HeroLeftStandard from '../components/HeroLeftStandard'
import HeroCenterStandard from '../components/HeroCenterStandard'
import { graphql, useStaticQuery } from 'gatsby'
import Newsletter from '../components/NewsletterPopup.js'

const Produkte = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProdukte(sort: { order: ASC, fields: order }) {
        edges {
          node {
            contentful_id
            internal{
              type
            }
            beschreibungSatz {
              internal {
                content
              }
            }
            slug
            title
            farbe
            logo {
              fixed(width: 150) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Newsletter />
      <SEO
        title="Produkte"
        description="Wir legen bei der Auswahl unserer Produkte immer großen Wert auf zuverlässige Funktion, regelmäßige technische Weiterentwicklung und eine zeitgemäße Updatesystematik."
      />
      <Container>
        <ContainerHero>
          <HeroLeftStandard>
            <PageTitle>Produkte</PageTitle>
          </HeroLeftStandard>
          <HeroCenterStandard>
            Wir legen bei der Auswahl unserer Produkte immer großen Wert auf
            zuverlässige Funktion, regelmäßige technische Weiterentwicklung und
            eine zeitgemäße Updatesystematik. Hier finden Sie eine Auswahl von
            Produkten, die wir gerne einsetzen.
          </HeroCenterStandard>
        </ContainerHero>
        <ContainerContent>
          <ContentProdukteLeistungen transfer={data.allContentfulProdukte} />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export default Produkte
